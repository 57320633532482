import React, { useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import { H1, Label, Title } from "@motorata/base-ui.components.atoms.typography"
import { formatPrice } from "@motorata/base-ui.utils.finance"
import Alert from "@motorata/base-ui.components.molecules.alert"
import { translate, translateBrand } from "@motorata/base-ui.data"
import Button from "@motorata/base-ui.components.atoms.button";
import EmailOfferModal from "../EmailOfferModal";

const BasicStep = ({
  state,
  calculation,
  calculationParams,
}: {
  state: any
  calculation: any
  calculationParams: any
}) => {
  const [showEmailOfferModal, setShowEmailOfferModal] = useState(false)

  const numberPrice = calculationParams.price
  const nett = calculationParams.priceType === "nett" ? numberPrice : numberPrice / 1.23
  const gross = nett * 1.23

  return (
    <>
      <Box marginBottom="S">
        <Accordion title="Twój przedmiot" wrapperProps={{ marginBottom: "S" }}>
          <Grid>
            <Title label="Stan" marginBottom="0">{`${translate(state.state)}`}</Title>
            <Title label="Marka" marginBottom="0">{`${translateBrand(state.brand)}`}</Title>
            <Title label="Model" marginBottom="0">{`${state.model}`}</Title>
            <Title label="Wartość netto" marginBottom="0">{`${formatPrice(nett)} zł`}</Title>
            <Title label="Wartość brutto" marginBottom="0">{`${formatPrice(gross)} zł`}</Title>
          </Grid>
        </Accordion>
      </Box>
      <Box marginTop="S">
        <Alert type="warning" small>
          Przejdź dalej, aby poznać więcej ofert finansowania - współpracujemy z{" "}
          <b>15 instytucjami finansowymi</b>!
        </Alert>
      </Box>
      <Accordion title="Oferty finansowania">
        <OfferGrid>
          <Title label="Rodzaj finansowania" marginBottom="0">{translate(calculationParams.type)}</Title>
          <Title label="Ilość rat" marginBottom="0">{`${calculationParams.period} mies.`}</Title>
          <Title label="Wpłata własna (%)" marginBottom="0">{`${calculationParams.payment}%`}</Title>
          <Title label="Wykup (%)" marginBottom="0">{`${calculationParams.repurchase}%`}</Title>
          <Button type="secondary" icon="email" onClick={() => setShowEmailOfferModal(true)}>
            Pobierz pełną ofertę PDF
          </Button>
        </OfferGrid>
        <StyledPriceWrapper justifyContent="space-between">
          <Label color="white">Twoja rata</Label>
          <H1 margin="0" color="white">{`${calculation?.price} zł ${calculation?.type}`}</H1>
        </StyledPriceWrapper>
      </Accordion>
      <EmailOfferModal show={showEmailOfferModal} setShow={setShowEmailOfferModal} />
    </>
  )
}

export default BasicStep

const StyledPriceWrapper = styled(Row)`
  margin-top: ${({ theme }) => theme.space.S};
  background: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.space.S};
  width: calc(100% + ${({ theme }) => theme.space.S} * 2);
  margin-left: -${({ theme }) => theme.space.S};
  margin-bottom: -${({ theme }) => theme.space.S};
`

const OfferGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr max-content;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    >*:last-child{
      grid-column-start: 1;
      grid-column-end: 5;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;

    >*:last-child{
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
  `;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: ${({ gtc = " 1fr 1fr 1fr 2fr 2fr" }) => gtc};
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Wrapper = styled(Box)``
