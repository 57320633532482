import React from "react"
import styled from "styled-components"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import Input from "@motorata/base-ui.components.atoms.input"
import CheckboxesGroup from "@motorata/base-ui.components.atoms.checkboxes-group"
import { EMAIL_REGEX, PHONE_STRING_REGEX } from "@motorata/base-ui.utils.regex"
import Checkbox from "@motorata/base-ui.components.atoms.checkbox"
import { Label } from "@motorata/base-ui.components.atoms.typography"
import { useUser } from "../../../../hooks/useUser"

const ContactStep = ({
  state,
  onChange,
  errors,
  watch,
  register,
}: {
  state: any
  onChange: any
  errors: any
  watch: any
  register: any
}) => {
  const { user } = useUser()

  return (
    <Accordion title="Podaj dane kontaktowe">
      <CheckboxesGroup
        name="contactType"
        label="Wybierz preferowany kontakt"
        value={[...state.contactType, "email"]}
        options={[
          ["Wiadomość e-mail", "email"],
          ["Rozmowa telefoniczna", "telefon"],
          ["Wiadomość SMS", "sms"],
          ["Wiadomość Whatsapp", "whatsaap"],
        ]}
        onChange={onChange}
        marginBottom="S"
      />
      <Grid marginBottom="S">
        <Input
          name="name"
          label="Imię"
          error={errors.firstName}
          defaultValue={user?.firstName}
          value={watch("firstName")}
          rhf={register("firstName", {
            required: true,
          })}
        />
        <Input
          name="name"
          label="Nazwisko"
          error={errors.lastName}
          value={watch("lastName")}
          rhf={register("lastName", {
            required: true,
          })}
        />
      </Grid>
      <Grid marginBottom="M">
        <Input
          name="email"
          label="Adres e-mail"
          defaultValue={user?.email}
          error={errors.email}
          value={watch("email")}
          rhf={register("email", {
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: "Niepoprawny format email",
            },
          })}
        />
        <Input
          name="phone"
          label="Numer telefonu"
          elementType="pattern"
          format="+48 ### ### ###"
          formattedValue={true}
          error={errors.phone}
          value={watch("phone")}
          rhf={register("phone", {
            required: true,
            pattern: {
              value: PHONE_STRING_REGEX,
              message: "Niepoprawny format telefonu. +48 123 456 789",
            },
          })}
        />
      </Grid>
      <CheckboxesGroup
        name="contactType"
        label="Wybierz preferowane dni kontaktu"
        value={state.contactType}
        options={[
          ["Poniedziałek", "pon"],
          ["Wtorek", "wt"],
          ["Środa", "sr"],
          ["Czwartek", "czw"],
          ["Piątek", "pt"],
          ["Weekend", "Weekend"],
        ]}
        onChange={onChange}
        marginBottom="S"
      />
      <CheckboxesGroup
        name="contactType"
        label="Wybierz preferowane godziny kontaktu"
        value={state.contactType}
        options={[
          ["Jak najszybciej", "asap"],
          ["8:00 - 12:00", "morning"],
          ["12:00 - 16:00", "afternoon"],
          ["16:00 - 21:00", "evning"],
        ]}
        onChange={onChange}
        marginBottom="S"
      />
      <Box>
        <Label type="secondary" marginBottom="XS">
          Informacje prawne
        </Label>
        <Checkbox
          name="checkbox"
          value={watch("checkbox")}
          error={errors.checkbox}
          rhf={register("checkbox", {
            required: true,
          })}
        >
          *Oświadczam, że zapoznałem/-am się z{" "}
          <a href="/regulamin" target="_blank">
            Regulaminem
          </a>{" "}
          i{" "}
          <a href="/polityka-prywatnosci" target="_blank">
            Polityką prywatności
          </a>{" "}
          oraz akceptuję ich postanowienia.
        </Checkbox>
      </Box>
    </Accordion>
  )
}

export default ContactStep

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Wrapper = styled(Box)``
