import React from "react"
import "./styles/globals.css"
import { StyledComponentsProvider } from "@motorata/base-ui.providers.styled-components"
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import styled from "styled-components"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import { P } from "@motorata/base-ui.components.atoms.typography"

import A from "@motorata/base-ui.components.atoms.a";
// @ts-ignore
import LOGO from "./assets/logo.svg"
import Home from "./pages/home";
import Calculator from "./pages/calculator"
import UserProvider from "./providers/UserProvider";
import Partners from "./pages/partners";
import Promotion from "./pages/calculator-promotion";
import Success from "./pages/calculator-success";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

function App() {
  return (
    <StyledComponentsProvider theme="motoup">
      {/*<script id="segmentScript" dangerouslySetInnerHTML={{ __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ZPwb77NBw6McBdolblXSzf5d3cNwpStZ";;analytics.SNIPPET_VERSION="4.15.3";*/}
      {/*    analytics.load(${process.env.REACT_APP_SEGMENT_KEY}");*/}
      {/*    analytics.page();*/}
      {/*  }}();` }}/>*/}
      <UserProvider>
        <Wrapper>
          <Box>
            <LogoWrapper>
              <img src={LOGO} />
            </LogoWrapper>
            <Content>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/calculator" element={<Calculator />} />
                  <Route path="/partner" element={<Partners />} />
                  <Route path="/promotion" element={<Promotion />} />
                  <Route path="/success" element={<Success />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
              </BrowserRouter>
            </Content>
          </Box>
          <Box>
            <Row justifyContent="center" marginBottom="S">
              <A href="https://motoup.pl/polityka-prywatnosci" type="third" target="_blank">
                Polityka prywatności
              </A>
              <A href="https://motoup.pl/regulamin" type="third" target="_blank">
                Regulamin
              </A>
            </Row>
            <P small fontSize="S" textAlign="center" margin="0">
              Powyższe oferty mają charakter informacyjny i nie stanowią oferty handlowej w rozumieniu
              art. 66 §1 Kodeksu Cywilnego”
            </P>
          </Box>
        </Wrapper>
      </UserProvider>
    </StyledComponentsProvider>
  )
}

export default App

const LogoWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space.L};

  img {
    display: block;
    height: 50px;
    margin: 0 auto;
  }

  @media (max-width: 1300px) {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: white;
    padding: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.color.fourth};
    z-index: 999;

    img {
      height: 30px;
    }
  }
`

const Content = styled.div`
  max-width: 1400px;
  width: 90%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space.S} 0;
  `;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.color.background};
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1300px) {
    padding: 0;
    padding-top: calc(63px);
    padding-bottom: 10rem;
  }
`
