import React, { useEffect } from "react"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import CalculationBlock from "@motorata/base-ui.components.shared.calculation-block";

const FinanceStep = ({ initParams, onChangeParams, onChangeCalculation, onChangeLoading }: { initParams: any; onChangeParams: any, onChangeCalculation: any, onChangeLoading: any }) => {
  return <Accordion title="Dopasuj swoje finansowanie">
    <CalculationBlock initParams={initParams} onChangeParams={onChangeParams} onChangeCalculation={onChangeCalculation} onChangeLoading={onChangeLoading} />
  </Accordion>
}

export default FinanceStep
