import React from "react"
import styled from "styled-components"
import { Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import Radio from "@motorata/base-ui.components.atoms.radio"
import Select from "@motorata/base-ui.components.atoms.select"
import Input from "@motorata/base-ui.components.atoms.input"
import { BRANDS_ARRAY, STATES_ARRAY, YEAR_ARRAY } from "@motorata/base-ui.data"

const BasicStep = ({ state, onChange }: { state: any; onChange: any }) => {
  return (
    <Accordion title="Podaj podstawowe dane">
      {/*<Alert type="warning" small><b>Pamietaj!</b> Wypełnienie formularza jest niezobowiązujące i pozoli Ci jedynie poznać przykładową ofertę finansowania.</Alert>*/}
      <Radio
        name="type"
        label="Rodzaj przedmiotu"
        value={state.type}
        options={[
          ["Samochód osobowy", "car"],
          ["Samochód dostawczy", "truck"],
        ]}
        onChange={onChange}
        marginBottom="S"
      />
      <Grid marginBottom="S">
        <Radio
          name="state"
          label="Stan przedmiotu"
          value={state.state}
          options={STATES_ARRAY.slice(0, 2)}
          onChange={onChange}
        />
        {state.state === "used" && (
          <Select
            name="year"
            label="Rocznik"
            value={state.year}
            options={YEAR_ARRAY.map(el => [`${el}`, `${el}`])}
            onChange={onChange}
          />
        )}
      </Grid>
      {state.type === "car" && (
        <Grid>
          <Select
            name="brand"
            label="Marka"
            value={state.brand}
            options={BRANDS_ARRAY}
            onChange={onChange}
          />
          <Input
            name="model"
            label="Model"
            value={state.model}
            onChange={onChange}
            disabled={!state.brand}
          />
        </Grid>
      )}
      {state.type === "truck" && (
        <Grid>
          <Input name="brand" label="Marka" value={state.brand} onChange={onChange} />
          <Input
            name="model"
            label="Model"
            value={state.model}
            onChange={onChange}
            disabled={!state.brand}
          />
        </Grid>
      )}
    </Accordion>
  )
}

export default BasicStep

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Wrapper = styled(Box)``
