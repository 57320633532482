import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { H3, P } from "@motorata/base-ui.components.atoms.typography"
import { Absolute, Box, common, Row } from "@motorata/base-ui.components.atoms._atoms"
import Icon from "@motorata/base-ui.components.atoms.icon"
import Button, { IconButton } from "@motorata/base-ui.components.atoms.button"

const ConsultationBanner = ({ step }: { step: number }) => {
  const [close, setClose] = useState(true)

  return (
    <Wrapper>
      <Row justifyContent="space-between">
        <Row alignItems="center" marginRight="S">
          <Icon icon="calendar-heart" color="green" size="15px" />
          <H3 marginLeft="S" marginBottom="0" color="green">
            Umów się na darmową konsultację!
          </H3>
        </Row>
        <IconButton icon={close ? "arrow-bottom" : "arrow-top"} onClick={() => setClose(!close)} />
      </Row>
      {!close && (
        <Box marginTop="XS">
          <P margin="0" marginBottom="S" fontSize="S">
            Przypiszemy do Ciebie indywidualnego doradcę klienta, z którym możesz omówić dowolne
            zagadnienie!
          </P>
          <a href="https://motoup.pl/konsultacja" target="_blank" rel="noreferrer">
            <Button type="secondary" color="green" width="100%">
              Dowiedz się więcej
            </Button>
          </a>
        </Box>
      )}
    </Wrapper>
  )
}

export default ConsultationBanner

const ImageWrapper = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
`

const Content = styled.div`
  margin-left: 15px;

  h3 {
    color: ${({ theme }) => theme.color.primary};
  }
`

const Wrapper = styled(Box)`
  ${common};
  width: 100%;
  padding: ${({ theme }) => theme.space.S};
  background: white;
  position: relative;
  border-radius: 3px;
  align-items: center;
  border-left: 3px solid ${({ theme }) => theme.color.green};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  background: ${({ theme }) => theme.color.greenBackground};

  // &:before {
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   width: calc(100% - 10px);
  //   left: 5px;
  //   top: 5px;
  //   height: calc(100% - 10px);
  //   border-radius: 3px;
  //   background: ${({ theme }) => theme.color.backgroundOpacity};
  //   //clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
  //   opacity: 0.2;
  // }
`
