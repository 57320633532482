import React from "react"
import styled from "styled-components"
import Accordion from "@motorata/base-ui.components.molecules.accordion"

import { H2, P } from "@motorata/base-ui.components.atoms.typography";
// @ts-ignore
import IMG from "../../../../assets/send.png"

const SuccessStep = () => {

  return (
    <Accordion title="Twój formularz został wysłany">
      <ImageWrapper>
        <img src={IMG} />
        <H2 marginBottom="S" marginTop="L" textAlign="center">Pomyślnie wysłaliśmy Twój formularz</H2>
        <P textAlign="center">Nasz doradca skontaktuje się z Tobą w wybranym przez Ciebie terminie!</P>
      </ImageWrapper>
    </Accordion>
  )
}

export default SuccessStep

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  max-width: 500px;
  margin: 0 auto;
  
  img{
    height: 150px;
    display: block;
    margin: 0 auto;
  }
`;
