import React, { useState, createContext, ReactNode, useEffect } from "react"
import { LocalStorageService } from "@motorata/base-ui.services.api-service"
import { ActiveCampaignService } from "@motorata/base-ui.services.active-campaign-service"
import { AnalyticsService } from "@motorata/base-ui.services.segment-service";

type UserType = {
  firstName: string
  lastName?: string
  email: string
}

export const UserContext = createContext<{
  user: null | UserType
  initUser: (firstName: string, email: string) => void
  saveUser: (firstName: string, email: string) => void
}>({
  user: null,
  initUser: () => {},
  saveUser: () => {},
})

const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<null | UserType>(null)

  const saveUser = (firstName: string, email: string) => {
    const obj = {
      firstName,
      email
    }

    setUser(obj)
    LocalStorageService.setObject("user", JSON.stringify(obj))
  }

  const initUser = (firstName: string, email: string) => {
    saveUser(firstName, email)
    AnalyticsService.track("Calculator Step", {
      step: "success-user",
    })
    ActiveCampaignService.set({
      firstName: firstName,
      email: email,
      list: "motoup",
      tags: "motoup-caluculator-init-user",
    })
  }

  useEffect(() => {
    const storeUser = LocalStorageService.getObject("user")
    if (storeUser) {
      setUser(JSON.parse(storeUser))
      AnalyticsService.track("Calculator Step", {
        step: "success-user",
      })
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        user,
        initUser,
        saveUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
