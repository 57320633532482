import React, { useCallback, useState } from "react"
import styled from "styled-components"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import Input from "@motorata/base-ui.components.atoms.input"
import { EMAIL_REGEX, PHONE_STRING_REGEX } from "@motorata/base-ui.utils.regex"
import { H1, Label, Title } from "@motorata/base-ui.components.atoms.typography"
import Checkbox from "@motorata/base-ui.components.atoms.checkbox"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import { useForm } from "react-hook-form"
import CalculationBlock from "@motorata/base-ui.components.shared.calculation-block"
import Button from "@motorata/base-ui.components.atoms.button"
import { LeadService } from "@motorata/base-ui.services.api-service"
import { removeSpacesFromPhoneNumber } from "@motorata/base-ui.utils.string"
import { translate, translateBrand } from "@motorata/base-ui.data"
import { ActiveCampaignService } from "@motorata/base-ui.services.active-campaign-service"

const Index = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const [sending, setSending] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    // setSending(true)
    // try {
    //
    // } catch (e) {
    //   console.log("[ERROR LeadService]:", e)
    //   alert("Przepraszamy ale nie udało się wysłać emaila. Prosimy spróbować później.")
    // }
  }, [])

  return (
    <Wrapper>
      <Content>
        <Accordion
          title="Twój przedmiot"
          wrapperProps={{ marginBottom: "S", height: "max-content" }}
        >
          <ImageWrapper marginBottom="S"></ImageWrapper>
          <Box marginBottom="S">
            <H1 marginBottom="XXS">Audi A6</H1>
            <Label type="secondary">M pakie 4x4</Label>
          </Box>
          <IconsWrapper>
            <Label type="secondary" icon="fuel" color="second">
              Benzyna
            </Label>
            <Label type="secondary" icon="transmission" color="second">
              Automatyczna
            </Label>
            <Label type="secondary" icon="power" color="second">
              120 KM
            </Label>
          </IconsWrapper>
        </Accordion>
        <Accordion title="Dopasuj finansowanie">
          <CalculationBlock initParams={{ price: 80000 }} showValueSlider={false} />
        </Accordion>
        <Accordion title="Podaj dane kontaktowe" wrapperProps={{ height: "max-content" }}>
          <Grid marginBottom="S">
            <Input
              name="name"
              label="Imię"
              error={errors.firstName}
              value={watch("firstName")}
              rhf={register("firstName", {
                required: true,
              })}
            />
            <Input
              name="name"
              label="Nazwisko"
              error={errors.lastName}
              value={watch("lastName")}
              rhf={register("lastName", {
                required: true,
              })}
            />
          </Grid>
          <Grid marginBottom="M">
            <Input
              name="email"
              label="Adres e-mail"
              error={errors.email}
              value={watch("email")}
              rhf={register("email", {
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Niepoprawny format email",
                },
              })}
            />
            <Input
              name="phone"
              label="Numer telefonu"
              elementType="pattern"
              format="+48 ### ### ###"
              formattedValue={true}
              error={errors.phone}
              value={watch("phone")}
              rhf={register("phone", {
                required: true,
                pattern: {
                  value: PHONE_STRING_REGEX,
                  message: "Niepoprawny format telefonu. +48 123 456 789",
                },
              })}
            />
          </Grid>
          <Box marginBottom="M">
            <Label type="secondary" marginBottom="XS">
              Informacje prawne
            </Label>
            <Checkbox
              name="checkbox"
              value={watch("checkbox")}
              error={errors.checkbox}
              rhf={register("checkbox", {
                required: true,
              })}
            >
              *Oświadczam, że zapoznałem/-am się z{" "}
              <a href="/regulamin" target="_blank">
                Regulaminem
              </a>{" "}
              i{" "}
              <a href="/polityka-prywatnosci" target="_blank">
                Polityką prywatności
              </a>{" "}
              oraz akceptuję ich postanowienia.
            </Checkbox>
          </Box>
          <Button width="100%" loading={sending} onClick={handleSubmit(onSubmit)}>
            Wyślij
          </Button>
        </Accordion>
      </Content>
    </Wrapper>
  )
}

export default Index

const IconsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 5px;

  label {
    margin-right: 15px;
    margin-bottom: 10px;
  }
`;

const ImageWrapper = styled(Box)`
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
  overflow: hidden;
  padding-top: 56%;
`

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 1300px) {
    grid-template-columns: 4fr 2fr;
    width: 100%;

    > div:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    > div:nth-child(2) {
      grid-column-start: 1;
      grid-row-start: 2;
    }

    > div:nth-child(3) {
      grid-column-start: 2;
      grid-row-start: 2;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.space.S};

    > div:nth-child(1) {
      grid-column-end: 2 !important;
    }

    > div:nth-child(2) {
      grid-row-start: 3 !important;
      grid-column-end: 2;
    }

    > div:nth-child(3) {
      grid-row-start: 2 !important;
      grid-column-start: 1 !important;
    }
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
`
