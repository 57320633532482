import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import { Absolute, Box, Row } from "@motorata/base-ui.components.atoms._atoms"
import { Label, P, Title } from "@motorata/base-ui.components.atoms.typography"
import { useSearchParams } from "react-router-dom"
import { convertToNumber } from "@motorata/base-ui.utils.number"
import StepsNavigation from "@motorata/base-ui.components.molecules.steps-navigation"
import Button from "@motorata/base-ui.components.atoms.button"
import { motoupTheme } from "@motorata/base-ui.theme"
import Spinner from "@motorata/base-ui.components.atoms.spinner"
import { formatPrice, getValidRVRange } from "@motorata/base-ui.utils.finance"
import { useForm } from "react-hook-form"
import { CalculateParamsType, CalculateType } from "@motorata/base-ui.types.api"
import { LeadService } from "@motorata/base-ui.services.api-service"
import ConsultationBanner from "../../components/shared/ConsultationBanner"
import BasicStep from "./components/setps/BasicStep"
import FinanceStep from "./components/setps/FinanceStep"
import ContactStep from "./components/setps/ContactStep"
import SuccessStep from "./components/setps/SuccessStep"
import OfferStep from "./components/setps/OfferStep"
import { removeSpacesFromPhoneNumber } from "@motorata/base-ui.utils.string"
import { translate, translateBrand } from "@motorata/base-ui.data"
import { useUser } from "../../hooks/useUser"
import InitUserModal from "./components/InitUserModal"
import { ActiveCampaignService } from "@motorata/base-ui.services.active-campaign-service"
import { AnalyticsService } from "@motorata/base-ui.services.segment-service"

const Index = () => {
  const stepsArray = [
    {
      title: "Podstawowe dane",
      text: "Podaj podstawowe dane Twojego przedmiotu",
    },
    {
      title: "Dopasuj finansowanie",
      text: "Dopasuj warunki swojej umowy leasingowej",
    },
    {
      title: "Przewidywana kalkulacja",
      text: "Zapoznaj się z proponowaną ofertą oraz harmonogramem",
    },
    {
      title: "Podsumowanie",
      text: "Dokończ proces kalkulacji Twojego finansowania",
    },
  ]

  const { user } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const queryPrice = convertToNumber(searchParams.get("price") ?? "120000") ?? 120000
  const queryPeriod = convertToNumber(searchParams.get("period") ?? "60") ?? 60
  const queryPayment = convertToNumber(searchParams.get("payment") ?? "40") ?? 40

  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(true)
  const [step, setStep] = useState(0)
  const [state, setState] = useState({
    type: "car",
    state: "brandNew",
    year: 2023,
    brand: "",
    model: "",
    contactType: ["email", "telefon", "sms", "whatsaap"],
    name: "",
    email: "",
    phone: "",
  })

  const [calculation, setCalculation] = useState<CalculateType>()
  const [calculationParams, setCalculationParams] = useState<CalculateParamsType>({
    type: "leasing",
    price: queryPrice,
    priceType: "gross",
    period: queryPeriod !== 0 ? queryPeriod : 60,
    payment: queryPayment !== 0 ? queryPayment : 40,
    repurchase: getValidRVRange(queryPeriod !== 0 ? queryPeriod : 60)[1],
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const handleOnChange = ({ name, value }: any) => {
    setState({
      ...state,
      [name]: value,
    })
  }

  const handleCalculationParamsChange = (e: CalculateParamsType) => {
    setCalculationParams(e)
    AnalyticsService.track("Calculator Calculation", e)
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    let stepName
    switch (step) {
      case 0:
        stepName = "basic-information"
        break
      case 1:
        stepName = "finance-information"
        break
      case 2:
        stepName = "offer-information"
        break
      case 3:
        stepName = "contact-information"
        break
      case 4:
        stepName = "success"
        break
    }
    AnalyticsService.track("Calculator Step", {
      step: stepName
    })
  }, [step])

  const onSubmit = useCallback(
    async (el: any) => {
      setSending(true)
      try {
        await LeadService.add("mup_calculator", {
          firstName: el.firstName,
          lastName: el.lastName,
          email: el.email,
          phone: removeSpacesFromPhoneNumber(el.phone),
          message: `${translate(state.state)} ${translateBrand(state.brand)} ${state.model} ${state.year} ${
              calculationParams.price
          } PLN ${calculationParams.priceType} / ${calculationParams.period} msc. / ${
              calculationParams.payment
          }% / ${calculationParams.repurchase}% / ${
              calculation?.price
          } zł/m-c ${state.contactType.join(", ")}`,
        })
        ActiveCampaignService.set({
          firstName: el.firstName,
          lastName: el.lastName,
          email: el.email,
          phoneNumber: removeSpacesFromPhoneNumber(el.phone),
          list: "motoup",
          tags: "motoup-caluculator-finish",
        })
        setStep(4)
        setSending(false)
      } catch (e) {
        console.log("[ERROR LeadService.add[mup_calculator]:", e)
        alert("Przepraszamy ale nie udało się wysłać emaila. Prosimy spróbować później.")
      }
    },
    [state, calculationParams, calculation],
  )

  const handlePreviousStep = useCallback(() => {
    const tmp = step - 1
    setStep(tmp)
  }, [step])

  const handleNextStep = useCallback(() => {
    const tmp = step + 1
    setStep(tmp)
  }, [step])

  useEffect(() => {
    if (user && state.brand && state.model && step === 1) {
      ActiveCampaignService.set({
        email: user.email,
        tags: "motoup-caluculator-product",
        motoup_calculator_product: `${translateBrand(state.brand)} ${state.model}`,
        motoup_calculator_rate: `${calculation?.price} zł/m-c`,
        motoup_calculator_promo_rate: `${calculation?.price_promo} zł/m-c`,
        motoup_calculator_params: JSON.stringify(calculationParams),
      })
    }
  }, [step, calculation])

  return (
    <>
      <Content currentStep={step}>
        <NavigationWrapper>
          {step !== 4 && <StepsNavigation currentStep={step} steps={stepsArray} />}
        </NavigationWrapper>
        <StepsWrapper>
          {step === 0 && <BasicStep state={state} onChange={handleOnChange} />}
          {step === 1 && (
            <FinanceStep
              initParams={calculationParams}
              onChangeParams={handleCalculationParamsChange}
              onChangeCalculation={setCalculation}
              onChangeLoading={setLoading}
            />
          )}
          {step === 2 && (
            <OfferStep
              state={state}
              calculationParams={calculationParams}
              calculation={calculation}
            />
          )}
          {step === 3 && (
            <ContactStep
              state={state}
              onChange={handleOnChange}
              errors={errors}
              watch={watch}
              register={register}
            />
          )}
          {step === 4 && <SuccessStep />}
          <ButtonsWrapper marginTop="S" justifyContent="flex-end">
            <Box>
              {step > 0 && step !== 4 && (
                <Button type="third" onClick={handlePreviousStep}>
                  Wróć
                </Button>
              )}
            </Box>
            {step !== 4 && (
              <Button
                loading={sending}
                onClick={step === 3 ? handleSubmit(onSubmit) : handleNextStep}
                disabled={step === 0 ? !(state.brand && state.model) : false}
              >
                {step === 3 ? "Wyślij" : "Dalej"}
              </Button>
            )}
            {step === 4 && (
              <a href="https://motoup.pl" style={{ width: "100%" }}>
                <Button width="100%">Wróć do strony głównej</Button>
              </a>
            )}
          </ButtonsWrapper>
        </StepsWrapper>
        {(step === 0 || step === 1) && (
          <Box>
            {step === 1 && (
              <Accordion
                title="Twoje finansowanie"
                background="primary"
                titleColor="white"
                wrapperProps={{ marginBottom: "S" }}
              >
                <Title
                  label="Rodzaj finansowania"
                  labelColor="white"
                  marginBottom="S"
                  color="white"
                >
                  {translate(calculationParams.type)}
                </Title>
                <Title label="Rata już od" labelColor="white" marginBottom="0" color="white">{`${
                  calculation && formatPrice(calculation?.price)
                } zł ${calculation?.type}`}</Title>
                {loading && (
                  <Absolute
                    width="100%"
                    height="100%"
                    left="0"
                    top="0"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor={motoupTheme.color.primary}
                  >
                    <Spinner white />
                  </Absolute>
                )}
              </Accordion>
            )}
            <ConsultationBanner step={step} />
          </Box>
        )}
      </Content>
      {/*<InitUserModal />*/}
    </>
  )
}

export default Index

const NavigationWrapper = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
`

const ButtonsWrapper = styled(Row)`
  z-index: 999;
  justify-content: space-between;

  @media (max-width: 800px) {
    padding: ${({ theme }) => theme.space.S};
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: white;
    box-shadow: ${({ theme }) => theme.variable.boxShadow};
  }
`

const StepsWrapper = styled.div`
  position: relative;
`

const Content = styled.div<{
  currentStep: number
}>`
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-gap: ${({ theme }) => theme.space.S};

  ${({ currentStep }) =>
    (currentStep == 2 || currentStep == 3) &&
    css`
      > div:nth-child(2) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    `};

  @media (max-width: 1300px) {
    grid-template-columns: 4fr 2fr;
    width: 100%;

    > div:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    > div:nth-child(2) {
      grid-column-start: 1;
      grid-row-start: 2;

      ${({ currentStep }) =>
        currentStep > 1 &&
        css`
          grid-column-end: 3;
        `};
    }

    > div:nth-child(3) {
      grid-column-start: 2;
      grid-row-start: 2;
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.space.S};

    > div:nth-child(1) {
      grid-column-end: 2 !important;
    }

    > div:nth-child(2) {
      grid-row-start: 3 !important;
      grid-column-end: 2;
    }

    > div:nth-child(3) {
      grid-row-start: 2 !important;
      grid-column-start: 1 !important;
    }
  }
`
