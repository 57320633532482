import React, { useCallback, useEffect } from "react"
import Modal from "@motorata/base-ui.components.molecules.modal"
import { P } from "@motorata/base-ui.components.atoms.typography"
import { useForm } from "react-hook-form"
import Input from "@motorata/base-ui.components.atoms.input"
import { EMAIL_REGEX } from "@motorata/base-ui.utils.regex"
import Checkbox from "@motorata/base-ui.components.atoms.checkbox"
import Button from "@motorata/base-ui.components.atoms.button"
import { useUser } from "../../../hooks/useUser"
import { POLITYKA_LINK, REGULAMIN_LINK } from "../../../constans/links"
import { AnalyticsService } from "@motorata/base-ui.services.segment-service"
import { ActiveCampaignService } from "@motorata/base-ui.services.active-campaign-service"

const EmailOfferModal = ({ show, setShow }: { show: boolean; setShow: (e: boolean) => void }) => {
  const { user, saveUser } = useUser()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = useCallback(async (el: any) => {
    saveUser(el.firstName, el.email)
    AnalyticsService.track("Calculator Step", {
      step: "success-download-offer",
    })
    ActiveCampaignService.set({
      firstName: el.firstName,
      email: el.email,
      list: "motoup",
      tags: "motoup-caluculator-download-offer",
    })
    setShow(false)
  }, [])

  useEffect(() => {
    if (!show) {
      AnalyticsService.track("Calculator Step", {
        step: "init-download-offer",
      })
    }
  }, [show])

  return (
    <Modal title="Pobierz pełną ofertę" show={show} setShow={setShow}>
      <P small marginBottom="S">
        Podaj podstawowe dane, aby pobrać szczegółową ofertę finansowania.
      </P>
      <Input
        name="name"
        label="Imię"
        error={errors.firstName}
        value={watch("firstName")}
        rhf={register("firstName", {
          required: true,
        })}
        marginBottom="S"
      />
      <Input
        name="email"
        label="Adres e-mail"
        error={errors.email}
        value={watch("email")}
        rhf={register("email", {
          required: true,
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny format email",
          },
        })}
        marginBottom="S"
      />
      <Checkbox
        name="checkbox"
        value={watch("checkbox")}
        error={errors.checkbox}
        rhf={register("checkbox", {
          required: true,
        })}
        marginBottom="S"
      >
        *Oświadczam, że zapoznałem/-am się z{" "}
        <a href={REGULAMIN_LINK} target="_blank" rel="noreferrer">
          Regulaminem
        </a>{" "}
        i{" "}
        <a href={POLITYKA_LINK} target="_blank" rel="noreferrer">
          Polityką prywatności
        </a>{" "}
        oraz akceptuję ich postanowienia.
      </Checkbox>
      <Button onClick={handleSubmit(onSubmit)} width="100%">
        Pobierz ofertę
      </Button>
    </Modal>
  )
}

export default EmailOfferModal
