import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import Accordion from "@motorata/base-ui.components.molecules.accordion"
import Input from "@motorata/base-ui.components.atoms.input"
import { EMAIL_REGEX, PHONE_STRING_REGEX } from "@motorata/base-ui.utils.regex"
import { Label } from "@motorata/base-ui.components.atoms.typography"
import Checkbox from "@motorata/base-ui.components.atoms.checkbox"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import { useForm } from "react-hook-form"
import Button from "@motorata/base-ui.components.atoms.button"
import { LeadService } from "@motorata/base-ui.services.api-service"
import { removeSpacesFromPhoneNumber } from "@motorata/base-ui.utils.string"
import { ActiveCampaignService } from "@motorata/base-ui.services.active-campaign-service"
import Spinner from "@motorata/base-ui.components.atoms.spinner"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AnalyticsService } from "@motorata/base-ui.services.segment-service"
import { useUser } from "../../hooks/useUser"
import { POLITYKA_LINK, REGULAMIN_LINK } from "../../constans/links"

const Index = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const { user } = useUser()
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()

  const onSubmit = useCallback(async (el: any) => {
    setSending(true)
    try {
      await LeadService.add("mup_calculator_promo", {
        firstName: el.firstName,
        lastName: el.lastName,
        email: el.email,
        phone: removeSpacesFromPhoneNumber(el.phone),
        message: `${searchParams.get("product")} ${searchParams.get("rate")} ${searchParams.get(
          "promoRate",
        )}`,
      })
      ActiveCampaignService.set({
        firstName: el.firstName,
        lastName: el.lastName,
        email: el.email,
        phoneNumber: removeSpacesFromPhoneNumber(el.phone),
        list: "motoup",
        tags: "motoup-caluculator-finish",
      })
      setSending(false)
      navigate("/success")
    } catch (e) {
      console.log("[ERROR LeadService.add[mup_calculator_promo]:", e)
      alert("Przepraszamy ale nie udało się wysłać emaila. Prosimy spróbować później.")
    }
  }, [])

  useEffect(() => {
    AnalyticsService.track("Calculator Step", {
      step: "init-promotion",
    })
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  return (
    <Wrapper>
      {loading ? (
        <Spinner size="45px" />
      ) : (
        <Content>
          <Accordion title="Poznaj szczegóły oferty" wrapperProps={{ height: "max-content" }}>
            <Grid marginBottom="S">
              <Input
                name="name"
                label="Imię"
                error={errors.firstName}
                value={watch("firstName")}
                defaultValue={user?.firstName}
                rhf={register("firstName", {
                  required: true,
                })}
              />
              <Input
                name="name"
                label="Nazwisko"
                error={errors.lastName}
                value={watch("lastName")}
                rhf={register("lastName", {
                  required: true,
                })}
              />
            </Grid>
            <Grid marginBottom="M">
              <Input
                name="email"
                label="Adres e-mail"
                error={errors.email}
                value={watch("email")}
                defaultValue={user?.email}
                rhf={register("email", {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Niepoprawny format email",
                  },
                })}
              />
              <Input
                name="phone"
                label="Numer telefonu"
                elementType="pattern"
                format="+48 ### ### ###"
                formattedValue={true}
                error={errors.phone}
                value={watch("phone")}
                rhf={register("phone", {
                  required: true,
                  pattern: {
                    value: PHONE_STRING_REGEX,
                    message: "Niepoprawny format telefonu. +48 123 456 789",
                  },
                })}
              />
            </Grid>
            <Box marginBottom="M">
              <Label type="secondary" marginBottom="XS">
                Informacje prawne
              </Label>
              <Checkbox
                name="checkbox"
                value={watch("checkbox")}
                error={errors.checkbox}
                rhf={register("checkbox", {
                  required: true,
                })}
              >
                *Oświadczam, że zapoznałem/-am się z{" "}
                <a href={REGULAMIN_LINK} target="_blank" rel="noreferrer">
                  Regulaminem
                </a>{" "}
                i{" "}
                <a href={POLITYKA_LINK} target="_blank" rel="noreferrer">
                  Polityką prywatności
                </a>{" "}
                oraz akceptuję ich postanowienia.
              </Checkbox>
            </Box>
            <Button width="100%" loading={sending} onClick={handleSubmit(onSubmit)}>
              Wyślij
            </Button>
          </Accordion>
        </Content>
      )}
    </Wrapper>
  )
}

export default Index

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 600px;
  grid-gap: ${({ theme }) => theme.space.S};
  margin: 0 auto;
`

const Wrapper = styled.div`
  margin: 0 auto;
`
