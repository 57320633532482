import React from "react"
import styled from "styled-components"
import { Box } from "@motorata/base-ui.components.atoms._atoms"
import { Label, LabelH2, Title } from "@motorata/base-ui.components.atoms.typography"
import Icon from "@motorata/base-ui.components.atoms.icon"
import { IconButton } from "@motorata/base-ui.components.atoms.button"

const NavigateButton = ({
  href,
  label,
  title,
}: {
  href: string
  icon: string
  label: string
  title: string
}) => {
  return (
    <Box marginBottom="M">
      <a href={href}>
        <NavigateButtonWrapper>
          <Title label={label} margin="0">
            {title}
          </Title>
          <IconButton icon="arrow-right" />
        </NavigateButtonWrapper>
      </a>
    </Box>
  )
}

const Home = () => {
  return (
    <Box maxWidth="500px" margin="0 auto">
      <NavigateButton
        href="/calculator"
        icon="phone"
        label="Kalkulator"
        title="Oblicz finansowanie online"
      />
    </Box>
  )
}

export default Home

const NavigateButtonWrapper = styled(Box)`
  background: white;
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  display: flex;
  align-items: center;
  justify-content: space-between;
`
